import { Interpolation, Theme, css } from '@emotion/react'
import throttle from 'lodash/throttle'
import {
  ComponentPropsWithRef,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import smoothscroll from 'smoothscroll-polyfill'

import { useElementWidth } from '@/hooks/useElementRect'

import { CarouselNav, NavVariantOptions } from './CarouselNav'

interface Props extends ComponentPropsWithRef<'div'> {
  children: ReactNode
  navContainer?: HTMLElement | null
  navCss?: Interpolation<Theme>
  scrollAreaCss?: Interpolation<Theme>
  contentCss?: Interpolation<Theme>
  navVariant?: NavVariantOptions
  snap?: boolean
  navLink?: ReactNode
}

export const Carousel = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      navContainer,
      scrollAreaCss,
      contentCss,
      navCss,
      navVariant = 'OVERLAY',
      snap,
      navLink,
      ...props
    },
    ref
  ): JSX.Element => {
    const [scrollPos, setScrollPos] = useState(0)

    const contentRef = useRef<HTMLDivElement | null>(null)
    const sliderRef = useRef<HTMLDivElement | null>(null)
    const scrollWidthRef = useRef<HTMLDivElement | null>(null)

    const containerWidth = useElementWidth(sliderRef.current) || 0
    const contentWidth = useElementWidth(contentRef.current) || 0
    const scrollWidth = useElementWidth(scrollWidthRef.current) || 0

    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
      smoothscroll.polyfill()
      // Use loaded state so that carousel doesn't auto scroll to weird positions
      setLoaded(true)
      return () => {
        setLoaded(false)
      }
    }, [])

    const scrollEffectThrottler = useRef(
      throttle(() => {
        if (contentRef.current && sliderRef.current) {
          setScrollPos(
            contentRef.current?.getBoundingClientRect().x -
              sliderRef.current?.getBoundingClientRect().x
          )
        }
      }, 50)
    ).current

    useEffect(() => {
      const refCopy = sliderRef.current
      refCopy?.addEventListener('scroll', scrollEffectThrottler)
      return () => {
        refCopy?.removeEventListener('scroll', scrollEffectThrottler)
        scrollEffectThrottler.cancel()
      }
    }, [scrollEffectThrottler])

    const handleScrollBack = () => {
      sliderRef.current?.scrollBy({
        top: 0,
        left: -scrollWidth,
        behavior: 'smooth',
      })
    }
    const handleScrollForward = () => {
      sliderRef.current?.scrollBy({
        top: 0,
        left: scrollWidth,
        behavior: 'smooth',
      })
    }

    const navRef = useRef<HTMLDivElement | null>(null)
    const navVisible = sliderRef && containerWidth < contentWidth - 20

    const navPortalTarget = navContainer || navRef.current

    const styles = {
      outer: css`
        position: relative;
        overflow: hidden;
        ${navVariant === 'ABOVE' &&
        (!navVisible
          ? css`
              margin-top: 1.5rem;
            `
          : css`
              margin-top: 0 !important;
            `)}
        [data-scroll-width] {
          width: var(--scroll-width, 100%);
          position: absolute;
        }
      `,
      slider: css`
        position: relative;
        height: 100%;
        > div {
          position: relative;
          display: flex;
          overflow-x: auto;
          overflow-y: visible;
          width: 100%;
          -webkit-overflow-scrolling: touch;
          scroll-snap-type: ${loaded && snap ? 'x mandatory' : 'unset'};
          // Hide scrollbar
          scrollbar-width: none;
          -ms-overflow-style: none;
          overflow: -moz-scrollbars-none;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      `,
      content: css`
        position: relative;
        min-height: min-content;
        display: flex;
        box-sizing: content-box;
        padding: 0 var(--margin);
        > * {
          scroll-snap-align: start;
        }
      `,
      scrollArea: css`
        scroll-padding-left: var(--margin);
      `,
    }

    return (
      <div
        css={styles.outer}
        ref={ref}
        {...props}
      >
        {!navContainer && <div ref={navRef} />}
        {navVisible &&
          navPortalTarget &&
          createPortal(
            <CarouselNav
              navLink={navLink}
              css={navCss}
              onClickBack={handleScrollBack}
              backDisabled={scrollPos >= -10}
              onClickForward={handleScrollForward}
              forwardDisabled={
                containerWidth - scrollPos >= contentWidth - 10
              }
              navVariant={navVariant}
            />,
            navPortalTarget
          )}
        <div
          data-scroll-width
          ref={scrollWidthRef}
        />
        <div css={styles.slider}>
          <div
            css={[styles.scrollArea, scrollAreaCss]}
            ref={sliderRef}
          >
            <div
              css={[styles.content, contentCss]}
              ref={contentRef}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }
)

Carousel.displayName = 'Carousel'
